import { apiPath } from "@/config/apiPath";
import axios from "@/utils/axios";
import { getQueryString } from "@/utils/helper";

const loadFilterValue = () => {
  const filterValue = JSON.parse(localStorage.getItem("filterValue"));
  return filterValue
    ? filterValue
    : {
      filter: false, city: "",
      state: "",
      pincode: "",
      carpet_area: "",
      super_area: ""
    };
};

const state = {
  pageAttributes: {
    page: 1,
    itemsPerPage: 10,
  },
  filterValue: loadFilterValue(),
  properties: [],
  search: "",
  searchLoading: false,
  startSearch: false,
  closeSearchBar: false,
};

const getters = {
  getAllProperties: (state) => state.properties,
  getFilterValue: (state) => {
    return state.filterValue;
  },
};

const mutations = {
  setFilterValue: (state, payload) => {
    state.filterValue = payload;
    localStorage.setItem("filterValue", JSON.stringify(payload));
  },
  doNothing: (state) => state,
  setAllProperties: (state, payload) => (state.properties = payload),
  addToProperty: (state, payload) => state.properties.unshift(payload),
  updateToPropertyr: (state, updatedProperty) => {
    state.properties.forEach((prop) => {
      if (prop.id == updatedProperty.id) {
        // Update Data
      }
    });
  },
  removeProperties: (state, id) => {
    state.properties = state.properties.filter((u) => u.id !== id);
  },
  setSearch(state, value) {
    state.search = value;
  },
  setSearchLoading(state, value) {
    state.searchLoading = value;
  },
  setStartSearch(state, value) {
    state.startSearch = value;
  },
  setCloseSearchBar(state, value) {
    state.closeSearchBar = value;
  },
  setPageAttributes(state, value) {
    state.pageAttributes = value
  }
};

const actions = {
  updateFilterValue: ({ commit }, filterValue) => {
    commit("setFilterValue", filterValue);
  },
  updateSearch({ commit }, value) {
    commit("setSearch", value);
  },
  updateSearchLoading({ commit }, value) {
    commit("setSearchLoading", value);
  },
  updateStartSearch({ commit }, value) {
    commit("setStartSearch", value);
  },
  updateCloseSearchBar({ commit }, value) {
    commit("setCloseSearchBar", value);
  },
  updatePageAttributes({ commit }, value) {
    commit("setPageAttributes", value);
  },
  /**
   * @description Get All Property
   * @param {Number} data - properties Object
   * @returns Promise
   */

  async fetchAllProperties({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy=[], sortDesc=[], page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.properties.getAllProperties+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllProperties", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Update properties Profile
   * @param {Object} data - Update properties Data Object
   * @returns Promise
   */
  updateProperty({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPath.properties.updateProperty+`${data.id}`, data)
        .then((res) => {
          if (res.data.success) {
            commit("updateToPropertyr", data);
            resolve(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * @description Search Property
   * @param {Number} data - Property Object
   * @returns Promise
   */

  searchProperties({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        let name = options.filter;
        params = { name, all: true };
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage,
        };
        let name = options.filter;
        params = { name, ...params };
      }

      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.properties.searchProperties+`?${url}`)
        .then((res) => {
          if (res.data.success) {
            commit("setAllProperties", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Remove Property
   * @param {Object} data - Remove Property
   * @returns Promise
   */
  removePropertyById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPath.properties.removeProperty+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("removeProperties", id);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get properties Info by ID
   * @param {Number} id - properties ID
   * @returns Promise
   */
  fetchPropertiesByID({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPath.properties.getPropertyByID+`${id}`)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Create New properties 
   * @param {Object} data - properties data Object
   * @returns Promise
   */
  createProperties({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.properties.createProperty, data)
        .then((res) => {
          if (res.data.success) {
            commit("addToProperty", res.data.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
 * @description Upload File
 * @param {Object} data -  Upload File Property Object
 * @returns Promise
 */
  uploadFileByProperty({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.type);
    formData.append("id", data.id);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.properties.uploadFileByProperty, formData)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
 * @description Upload video
 * @param {Object} data -  Upload video of Property 
 * @returns Promise
 */
  uploadVideoFileByProperty({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.type);
    formData.append("id", data.id);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {

      axios
        .post(apiPath.properties.uploadVideoFileByProperty, formData)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },


  /**
   * @description Upload Video
   * @param {Object} data -  Upload Video Property Object
   * @returns Promise
   */
  uploadVideoByProperty({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.type);
    formData.append("id", data.id);
    formData.append("app_type", data.app_type);
    return new Promise((resolve, reject) => {
      axios
        .post(apiPath.properties.uploadVideo, formData)
        .then((res) => {
          if (res.data.success) {
            commit("doNothing");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
* @description Filter property
* @param {Number} id - pincode , state, city, super area, carpet area
* @returns Promise
*/
  async filterProperties({ commit }, options) {
    return new Promise((resolve, reject) => {
      let params = {};
      if (options.all) {
        params = {
          ...options,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          super_area: state.filterValue.super_area,
          carpet_area: state.filterValue.carpet_area
        };
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";

        params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sort_by,
          sort_order: sort_order,
          state: state.filterValue.state,
          city: state.filterValue.city,
          pincode: state.filterValue.pincode,
          super_area: state.filterValue.super_area,
          carpet_area: state.filterValue.carpet_area
        };
      }
      let url = getQueryString(params).substring(1);

      axios
        .get(apiPath.properties.filterProperties+`?${url}`)
        .then(async (res) => {
          if (res.data.success) {
            await commit("setAllProperties", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description On download button get data in excel sheet
   * @returns Promise
   */
  async getAllExcelSheetByDownload({ commit }) {
    return new Promise((resolve, reject) => {
      const params = {
        state: state.filterValue.state,
        city: state.filterValue.city,
        pincode: state.filterValue.pincode,
        super_area: state.filterValue.super_area,
        carpet_area: state.filterValue.carpet_area
      };
      let url = getQueryString(params).substring(1);
      axios
        .get(apiPath.properties.downloadProperties+`?${url}`)
        .then((res) => {
          resolve(res);
          commit("doNothing");
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
